import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';

import { SharedUiCalloutModule } from '@equito/shared/ui/callout';
import { RouterModule } from '@angular/router';
import { SharedUiIconsModule } from '@equito/shared/ui/icons';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { CrowdfundingUiActiveCampaignModule } from '@equito/crowdfunding/ui/active-campaign';

@NgModule({
  imports: [
    CommonModule,
    SharedUiCalloutModule,
    RouterModule.forChild([]),
    MatIconModule,
    SharedUiIconsModule,
    HttpClientModule,
    CrowdfundingUiActiveCampaignModule,
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class CrowdfundingUiFooterModule {}
