<equito-navigation
  class="navi"
  [class.landing-page]="landingPage"
></equito-navigation>

<ng-content></ng-content>

<equito-footer
  class="mt-auto"
  [class.landing-page]="landingPage"
></equito-footer>
