import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomIconsService } from './custom-icons.service';

@NgModule({
  imports: [CommonModule],
  providers: [CustomIconsService],
})
export class SharedUiIconsModule {
  constructor(private iconsService: CustomIconsService) {
    this.iconsService.registerIcons();
  }
}
